import React from "react"
import Faq from "./Faq"
import PersonalPicture from "../images/undraw_personal_notebook_sobb.svg"
import Footer from "./Footer"
function KnowMeBetter(){
    return (
        <div>
        <div className = "light-cover-bg">
        <div class="row" style={{margin:"auto"}}>
        <div class="col-md-5 col-xs-12 home-intro-col-light" id = "faq-image">
                <img src = {PersonalPicture} className="pl-16 pr-16 pt-16 pb-16 marginTopLaptops"/>
            </div>
            <div class="col-md-7 col-xs-12 home-intro-col-light outer-vertical-center" style={{justifyContent:"left "}} id="faq-text">
                <div  className=" inner-vertical-center">
                    <div>
                    <p className="h1 font-weight-light pb-16 pt-16 " >
                        Know me better!
                    </p>
                    </div>
                    <div>
                    <Faq 
                question = "1. What's my favorite thing about Software Development?" 
                answer = "I love the fact that I can create and develop something can be used by millions of people across the world, and can change lives. I also enjoy the combination of creativity, logic and problem solving involved in Software development"/>
            <Faq 
                question = "2. What do I do when I'm not coding?" 
                answer = "I'll mostly be reading a book, scrolling through Twitter or watching a new show on Netflix/Hulu"/>
            <Faq 
                question = "3. Which are my comfort-binge TV shows?" 
                answer = "Brooklyn nine-nine, HIMYM or Seinfeld"/>
            <Faq 
                question = "4. What is my greatest strength?" 
                answer = " It's my ability to constantly be curious, learn continuously and be receptive to new perspectives. "/>

            <Faq 
                question = "5. What's my biggest weakness?" 
                answer = "I bang my head for hours before seeking help from someone who could probably unblock me withing a few minutes. (Have recently started using Timeboxing as suggested by my manager and mentor and it helps)"/>
                    </div>
                </div>
            </div>
          
        </div>
        </div>
        {Footer}
        </div>
    )
}

export default KnowMeBetter