import React from "react"
import TimePass from "../images/undraw_contemplating.svg"
import ProgressBar from './ProgressBar'
function AboutMeInformal(){
    return (
        <div class = "dark-cover-bg font-color-white">        
        <div class="row" style={{margin:"auto"}}>
            <div class="col-md-7 col-xs-12 home-intro-col1 outer-vertical-center" style={{justifyContent:"center"}}>
                <div  className=" inner-vertical-center" style={{width:"70%"}}>
                    <div>
                    <p className="h1 font-weight-light pb-16 pt-16 mb-32">
                        My non-tech skills
                    </p>
                    </div>
                    <div>
                    <ProgressBar progressLabel = "Scrolling aimlessly on Twitter" progressValue = "99"/>
                    <ProgressBar progressLabel = "Being bright and chatty without morning coffee" progressValue = "2"/>
                    <ProgressBar progressLabel = "Reading, reading and reading some more" progressValue = "80"/>
                    <ProgressBar progressLabel = "Browsing Netflix/Hulu" progressValue = "72"/>
                    <ProgressBar progressLabel = "Dancing in public" progressValue = "12"/>
                    <ProgressBar progressLabel = "Having conversations with new folks" progressValue = "85"/>
                    </div>
                </div>
            </div>
            <div class="col-md-5 col-xs-12 home-intro-col1 marginTopLaptops">
                <img src = {TimePass} className="pl-16 pr-16 pt-16 pb-16"/>
            </div>
        </div>
    </div>
    )
}

export default AboutMeInformal