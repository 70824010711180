import React from "react"
import WorkingLate from "../images/undraw_working_late_pukg.svg"
import Navbar from "./Navbar"


function HomePageIntroduction(){
    return (
        <div class = "dark-cover-bg">
                      
<div class="row" style={{margin:"auto"}}>
  <div class="col-md-6 col-xs-12 home-intro-col1 outer-vertical-center">
        <div  className="justify-text font-color-white inner-vertical-center">
            <p className="h1 font-weight-light pb-16 pt-16">
                Hi, I am Aneri Shah
            </p>
            <p className="pt-16 pb-16 intro-text-formatting">
                A developer, problem-solver, amateur designer and a writer (hopefully!)
            </p>
            <p className="intro-text-formatting">
                I love building and creating interesting things with technology. Contact me if you’re building something interesting 
            </p>
        </div>
  </div>
  <div class="col-md-6 col-xs-12 home-intro-col1 marginTopLaptops">
      <img src = {WorkingLate} className="pl-16 pr-16 pt-16 pb-16"/>
    </div>
</div>
        </div>
    )
}

export default HomePageIntroduction