import React from "react"

function ProgressBar(props){
    return(
        // <div id = "progress">
        // <div className="progress-container">
        // <span className="progress-badge dark">{props.progressLabel}</span>
        //         <div className="progress">
        //             <div 
        //                 className="progress-bar" 
        //                 role="progressbar" 
        //                 aria-valuenow={props.progressValue} 
        //                 aria-valuemin="0" 
        //                 aria-valuemax="100"
        //                 style={{width: props.progressValue + '%'}}
        //             >
        //                 <span className="progress-value">
        //                     <span className="counter">{props.progressValue}</span>%</span>
        //             </div>
        //         </div>
        // </div>
        // </div>
        <div id = "progress">
            {/* <div className = "row">
                <div className="col-xs-6">
                <p className = "progressLabel">{props.progressLabel}</p>
                </div>
                <div className = "col-xs-6">
                <p>{props.progressValue}</p>
                </div>
            </div> */}

            <div class="row-progress-bar">
                <div>
                    {props.progressLabel}
                </div>
                <div style={{float:"right"}} className="pl-16">
                    {props.progressValue}%
                </div>
            </div>
        <div className="progress-container">
        {/* <span className="progress-badge dark">{props.progressLabel}</span> */}
                <div className="progress">
                    <div 
                        className="progress-bar" 
                        role="progressbar" 
                        aria-valuenow={props.progressValue} 
                        aria-valuemin="0" 
                        aria-valuemax="100"
                        style={{width: props.progressValue + '%'}}
                    >
                        {/* <span className="progress-value">
                            <span className="counter">{props.progressValue}</span>%</span> */}
                    </div>
                </div>
        </div>
        </div>
    )
}

export default ProgressBar;