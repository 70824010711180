import React from "react"

function Faq(props){
    return (
        <div className="faqContainer">
            <p className = "faqQuestion">{props.question}</p>
            <p className = "faqAnswer">{props.answer}</p>
        </div>
    )
}

export default Faq