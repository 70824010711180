import React from "react"
import MyCodeSnippets from "../images/undraw_my_code_snippets_lynx.svg"
import {Button} from "react-bootstrap"

function AboutMeFormal(){
    return (
    <div class = "light-cover-bg">        
        <div class="row" style={{margin:"auto"}}>
            <div class="col-md-5 col-xs-12 home-intro-col-light">
                <img src = {MyCodeSnippets} className="pl-16 pr-16 pt-16 pb-16"/>
            </div>
            <div class="col-md-7 col-xs-12 home-intro-col-light outer-vertical-center" style={{fontSize:"18px"}}>
                <div  className="justify-text inner-vertical-center">
                    <p className="h1 font-weight-light pb-16 pt-16">
                        About Me
                    </p>
                    <p className="pt-16 pb-16 ">
                        I am a Computer Science student pursuing my Masters at the University of Southern California. I am interested in cryptocurrency and mobile development. 
                    </p>
                    <p>
                         I really enjoy mobile app development and have worked with mobile technologies - Reactive Kotlin, Java etc., over the past few years. I am also a full stack web developer and have worked previously with React, Angular, Node and Flask.         
                    </p>
                    <p>
                        I am a curious person, I love reading and believe that continuous learning is essential for growth! 
                    </p>
                    <p>
                        Check out my resume or get in touch if you’re looking for someone  who is passionate about building products!
                    </p>
                    <div class = "row" style={{marginTop:"32px", marginBottom:"24px"}}>
                        <div className = "m8" >
                            <Button variant='outline-primary' id="myStyleButton">Resume </Button>
                        </div>
                        <div className="m8">
                            <Button variant="outline-primary" id="myStyleButton">Projects </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default AboutMeFormal