import React from "react"
import HomePageIntroducton from "../components/HomePageIntroduction"
import AboutMeFormal from "../components/AboutMeFormal"
import AboutMeInformal from "../components/AboutMeInformal"
import Footer from "../components/Footer"
import KnowMeBetter from "../components/KnowMeBetter"
import Navbar from "../components/Navbar"

require('typeface-poppins')
function Home() {
  return (
    <div>
      <Navbar/>
      <HomePageIntroducton/>
      <AboutMeFormal/>
      <AboutMeInformal/>
      <KnowMeBetter/>
      <Footer/>
    </div>
  );
}

export default Home
